import axios from 'axios';

axios.defaults.withCredentials = false;
//axios.defaults.baseURL = `https://data.polehawk.com/`;
axios.defaults.baseURL = `https://polehawk-backend-dev.freshembassy.com/`;

axios.defaults.timeout = 360000000;
export const price_link = "https://www.polehawk.com/pricing";

export const client = axios;
//export const serverPath = "https://data.polehawk.com/";
export const serverPath = "https://polehawk-backend-dev.freshembassy.com/";
export const s3Path = "https://s3polehawk.s3.us-west-2.amazonaws.com/";
export const appPath = "https://app.polehawk.com/";
export const isEnableVisibleAllDatabase = false;

function jsonConfig(config) {
	config.headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		...config.headers,
	};

	return config;
}

function uploadConfig(config) {
	config.headers = {
		...config.headers,
		'Content-Type': 'multipart/form-data'
	};

	return config;
}

export function setParameter (name, value) {
	// value = encodeURIComponent(value);

	name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
	let regex = new RegExp('([\\?&])(' + name + '=([^&#]*))');
	let results = regex.exec(document.location.search);

	let url = window.location.href;
	if (results === null) {
		if (window.location.search.length === 0) {
			url = url + '?';
		} else {
			url = url + '&';
		}
		url = url + name + '=' + value;
	} else {
		let newValue = name + '=' + value;
		url = url.replace(results[2], newValue);
	}
	window.history.replaceState({}, '', url);
}

export function isSharing() {
	if (getParameterByName("share_token") && getParameterByName("share_token") !== "" && 
	getParameterByName("project_id") && getParameterByName("project_id") !== "") {
		return true;
	}
	
	return false;
}

export function canEditItem(userInfo) {
	return userInfo && userInfo.role !== "viewer" && !isSharing()
}

export function isOwner(userInfo) {
	return userInfo && userInfo.role !== "viewer" && userInfo.role !== "user" && !isSharing()
}

export function compareShareInfo(token, project_id, password) {
	let share_project_id = localStorage.getItem("share_project_id");
	let share_token = localStorage.getItem("share_token");
	let share_password = window.share_password;

	if (share_project_id !== project_id || share_token !== token || share_password !== password) {
		return false;
	}

	return true;
}

export function saveShareInfo(token, project_id, password) {
	localStorage.setItem("share_project_id", project_id);
	localStorage.setItem("share_token", token);
	window.share_password = password;
}

export function getParameterByName (name) {
	name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
	let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	let results = regex.exec(document.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function request(config) {
	jsonConfig(config);

	return client.request(config);
}

function uploadRequest(config, onProgress) {
	uploadConfig(config);
	config.onUploadProgress = function(progressEvent) {
		if (onProgress) {
			onProgress(progressEvent);
		}
	}
	return client.request(config);
}

export function createLidarNewReport(payload) {
	return request({
		url: '/api/report/generateNewLidar',
		method: 'POST',
		data: payload
	});
}

export function createLidarDem(payload) {
	return request({
		url: '/api/report/generateLidarDem',
		method: 'POST',
		data: payload
	});
}

export function createImageReport(payload) {
	return request({
		url: '/api/report/generateImageReport',
		method: 'POST',
		data: payload
	});
}

export function createMapReport(payload) {
	return request({
		url: '/api/report/generateMapReport',
		method: 'POST',
		data: payload
	});
}

export function createNewImageReport(payload) {
	return request({
		url: '/api/report/generateNewImageReport',
		method: 'POST',
		data: payload
	});
}

export function startMapReport({token, id, project_id}) {
	localStorage.setItem("report_" + id, true);
	return request({
		url: '/api/report/startMapReport',
		method: 'POST',
		data: {token, id, project_id}
	});
}

export function startImageReport({token, id, project_id}) {
	localStorage.setItem("report_" + id, true);
	return request({
		url: '/api/report/startImageReport',
		method: 'POST',
		data: {token, id, project_id}
	});
}

export function startLidarReport({token, id, project_id}) {
	localStorage.setItem("report_" + id, true);
	return request({
		url: '/api/report/startLidarReport',
		method: 'POST',
		data: {token, id, project_id}
	});
}

export function startLidarDem({token, id, project_id}) {
	localStorage.setItem("report_" + id, true);
	return request({
		url: '/api/report/startLidarDem',
		method: 'POST',
		data: {token, id, project_id}
	});
}

export function startImageGrouper({token, image_source_id, kmz_source_id, kmz_label}) {
	return request({
		url: '/api/report/startImageGrouper',
		method: 'POST',
		data: {token, image_source_id, kmz_source_id, kmz_label}
	});
}

export function createDataset({token, name}) {
	return request({
		url: '/api/dataset/create',
		method: 'POST',
		data: {token, name}
	});
}

export function updateDataset(payload) {
	return request({
		url: '/api/dataset/update',
		method: 'POST',
		data: payload
	});
}

export function updateDatasetInfo(payload) {
	return request({
		url: '/api/dataset/update_info',
		method: 'POST',
		data: payload
	});
}

export function getFilesByDatasetID({dataset_id, token}) {
	return request({
		url: '/api/dataset/getfiles',
		method: 'POST',
		data: {dataset_id, token}
	});
}

export function deleteExistFile({id, dataset_id, token}) {
	return request({
		url: '/api/dataset/deleteFile',
		method: 'POST',
		data: {id, dataset_id, token}
	});
}

export function getProjectDatasetByID({id, project_id, token}) {
	return request({
		url: '/api/dataset/get',
		method: 'POST',
		data: {id, project_id, token}
	});
}

export function getDatasetByID({id, token}) {
	return request({
		url: '/api/dataset/get',
		method: 'POST',
		data: {id, token}
	});
}

export function deleteDatasetByID({id, token}) {
	return request({
		url: '/api/dataset/delete',
		method: 'POST',
		data: {id, token}
	});
}

export function startProcessDataset({id, token, attach}) {
	return request({
		url: '/api/dataset/start',
		method: 'POST',
		data: {id, token, attach}
	});
}

export function checkProcess({id, token}) {
	return request({
		url: '/api/dataset/check_process',
		method: 'POST',
		data: {id, token}
	});
}

export function uploadDatasetByID({ id, files, token, attach, onProgress}) {
	let formData = new FormData();
	formData.append('id', id);
	formData.append('token', token);
	formData.append('attach', attach);
	files.forEach(file => {
		formData.append('files[]', file, file.name);
	});

	return uploadRequest({
		url: '/api/dataset/upload',
		method: 'POST',
		data: formData,
	}, onProgress)
}

export function getDatasetList({token}) {
	return request({
		url: '/api/dataset/getlist',
		method: 'POST',
		data: {token}
	});
}

export function getSharingToken({token, project_id}) {
	return request({
		url: '/api/link/generate',
		method: 'POST',
		data: { token, project_id }
	});
}

export function createSharingLink({token, link_token, link_expired, project_id, password}) {
	return request({
		url: '/api/link/create',
		method: 'POST',
		data: {token, link_token, link_expired, project_id, password}
	});
}

export function getLinkList({token}) {
	return request({
		url: '/api/link/getlist',
		method: 'POST',
		data: {token}
	});
}

export function deleteLink({token, id}) {
	return request({
		url: '/api/link/delete',
		method: 'POST',
		data: {token, id}
	});
}

export function checkPasswordLink({ password, token, project_id }) {
	return request({
		url: '/api/link/check_password',
		method: 'POST',
		data: { password, token, project_id }
	});
}

export function getShareLink({ project_id, token }) {
	return request({
		url: '/api/link/get',
		method: 'POST',
		data: { project_id, token }
	});
}

export function updateLink({token, id, password, link_expired}) {
	return request({
		url: '/api/link/update',
		method: 'POST',
		data: {token, id, password, link_expired}
	});
}

export function extendLink({token, id}) {
	return request({
		url: '/api/link/extend',
		method: 'POST',
		data: {token, id}
	});
}

//Lidar Report Rule
export function createReportRule(payload) {
	return request({
		url: '/api/rule/create',
		method: 'POST',
		data: payload
	});
}

export function createRuleGroup(payload) {
	return request({
		url: '/api/rule/create_group',
		method: 'POST',
		data: payload
	});
}

export function createImageReportRule(payload) {
	return request({
		url: '/api/image_rule/create',
		method: 'POST',
		data: payload
	});
}

export function deleteReportRule(payload) {
	return request({
		url: '/api/rule/delete',
		method: 'POST',
		data: payload
	});
}

export function deleteRuleGroup(payload) {
	return request({
		url: '/api/rule/delete_group',
		method: 'POST',
		data: payload
	});
}

export function deleteImageReportRule(payload) {
	return request({
		url: '/api/image_rule/delete',
		method: 'POST',
		data: payload
	});
}

export function updateReportRule(payload) {
	return request({
		url: '/api/rule/update',
		method: 'POST',
		data: payload
	});
}

export function updateRuleGroup(payload) {
	return request({
		url: '/api/rule/update_group',
		method: 'POST',
		data: payload
	});
}

export function updateCSVRuleData(payload) {
	return request({
		url: '/api/rule/create_rule_table',
		method: 'POST',
		data: payload
	});
}

export function updateImageReportRule(payload) {
	return request({
		url: '/api/image_rule/update',
		method: 'POST',
		data: payload
	});
}

export function getReportRuleList({ token }) {
	return request({
		url: '/api/rule/getlist',
		method: 'POST',
		data: { token }
	});
}

export function getImageReportRuleList({ token }) {
	return request({
		url: '/api/image_rule/getlist',
		method: 'POST',
		data: { token }
	});
}

export function getReportRule({ token, id }) {
	return request({
		url: '/api/rule/get',
		method: 'POST',
		data: { token, id }
	});
}

export function getImageReportRule({ token, id }) {
	return request({
		url: '/api/image_rule/get',
		method: 'POST',
		data: { token, id }
	});
}

//end Lidar Report Rule



export function updateProjectVisibleAll({id, visible, token}) {
	return request({
		url: '/api/project/update_visible_all',
		method: 'POST',
		data: {id, visible, token}
	});
}

export function updateVisible({token, id, dataset_id, visible}) {
	return request({
		url: '/api/project/update_visible',
		method: 'POST',
		data: { token, id, dataset_id, visible }
	});
}

export function updateReportVisible({token, id, reportFiles}) {
	return request({
		url: '/api/dataset/update_visible_report',
		method: 'POST',
		data: { token, id, reportFiles }
	});
}

export function deleteDatasetFromProject({ id, token, dataset_id }) {
	return request({
		url: '/api/project/delete_dataset',
		method: 'POST',
		data: { id, token, dataset_id }
	});
}

export function createProject({token, name, description}) {
	return request({
		url: '/api/project/create',
		method: 'POST',
		data: { name, description, token }
	});
}

export function updateProject({token, id, name, description}) {
	return request({
		url: '/api/project/update',
		method: 'POST',
		data: { name, id, description, token }
	});
}

export function addDataset({token, id, dataset_id}) {
	return request({
		url: '/api/project/add_dataset',
		method: 'POST',
		data: { id, dataset_id, token }
	});
}

export function getProjects({ token }) {
	return request({
		url: '/api/project/getlist',
		method: 'POST',
		data: { token }
	});
}

export function getProjectByID({ id, token }) {
	return request({
		url: '/api/project/get',
		method: 'POST',
		data: { id, token }
	});
}

export function deleteProjectByID({ id, token }) {
	return request({
		url: '/api/project/delete',
		method: 'POST',
		data: { id, token }
	});
}

export function getAssetsByID({ token }) {
	return request({
		url: '/api/getAssets',
		method: 'POST',
		data: { token }
	});
}

export function getAsset({ id, token }) {
	return request({
		url: '/api/asset/get',
		method: 'POST',
		data: { id, token }
	});
}

export function createAsset({ name, detail, inspect, positions, visible, token, }) {
	return request({
		url: '/api/asset/create',
		method: 'POST',
		data: { name, detail, inspect, positions, visible, token }
	});
}

export function updateAssetVisibleAll({visible, token}) {
	return request({
		url: '/api/asset/update_visible_all',
		method: 'POST',
		data: {visible, token}
	});
}

export function deleteAllAssets({token}) {
	return request({
		url: '/api/asset/delete_all',
		method: 'POST',
		data: {token}
	});
}

export function getAnalytics({token}) {
	return request({
		url: '/api/get_analytics',
		method: 'POST',
		data: {token}
	});
}

export function getLastAnalytics({token}) {
	return request({
		url: '/api/get_last_analytics',
		method: 'POST',
		data: {token}
	});
}

export function login({ email, password, token}) {
	return request({
		url: '/api/login',
		method: 'POST',
		data: (token)?{ email, password, token}:{ email, password }
	});
}

export function checkExpire({ token }) {
	return request({
		url: '/api/check_expire',
		method: 'POST',
		data: { token }
	});
}

export function checkMaintenance() {
	return request({
		url: '/api/check_maintenance',
		method: 'POST',
		data: {}
	});
}

export function checkEmail(payload) {
	return request({
		url: '/api/check_email',
		method: 'POST',
		data: payload
	});
}

export function register(payload) {
	return request({
		url: '/api/register',
		method: 'POST',
		data: payload
	});
}

export function agreePolicy(payload) {
	return request({
		url: '/api/agree_policy',
		method: 'POST',
		data: payload
	});
}

export function verifyEmailForReset(payload) {
	return request({
		url: '/api/forget_password',
		method: 'POST',
		data: payload
	});
}

export function resetPassword(payload) {
	return request({
		url: '/api/update_password_reset',
		method: 'POST',
		data: payload
	});
}

export function verifyAuthentication(payload) {
	return request({
		url: '/api/verify_authentication',
		method: 'POST',
		data: payload
	});
}

export function checkAuthenticationCode(payload) {
	return request({
		url: '/api/check_authentication_code',
		method: 'POST',
		data: payload
	});
}

export function resendVerification(payload) {
	return request({
		url: '/api/resend_verification',
		method: 'POST',
		data: payload
	});
}

export function forgetPassword(payload) {
	return request({
		url: '/api/forget_password',
		method: 'POST',
		data: payload
	});
}

export function updatePasswordReset(payload) {
	return request({
		url: '/api/update_password_reset',
		method: 'POST',
		data: payload
	});
}

export function getUser({ token }) {
	return request({
		url: '/api/getUser',
		method: 'POST',
		data: { token }
	});
}

export function getUsers({ token }) {
	return request({
		url: '/api/getUsers',
		method: 'POST',
		data: { token }
	});
}

export function deleteUser({ token, user_id }) {
	return request({
		url: '/api/delete_user',
		method: 'POST',
		data: { token, user_id }
	});
}

export function updateSettings(payload) {
	return request({
		url: '/api/update_settings',
		method: 'POST',
		data: payload
	});
}

export function updateLabelProperty({ token, label_property,}) {
	return request({
		url: '/api/update_labelproperty',
		method: 'POST',
		data: { token, label_property }
	});
}

export function updateUser(payload) {
	return request({
		url: '/api/update_user',
		method: 'POST',
		data: payload
	});
}

export function updatePassword(payload) {
	return request({
		url: '/api/update_password',
		method: 'POST',
		data: payload
	});
}

export function createAnnotation(payload) {
	return request({
		url: '/api/annotation/create',
		method: 'POST',
		data: payload
	});
}

export function createImageAnnotation(payload) {
	return request({
		url: '/api/annotation/create',
		method: 'POST',
		data: payload
	});
}

export function updateAnnotationVisible({id, visible, token}) {
	return request({
		url: '/api/annotation/update_visible',
		method: 'POST',
		data: {id, visible, token}
	});
}

export function updateAnnotationAllVisible({project_id, dataset_id, file_id, visible, token}) {
	return request({
		url: '/api/annotation/update_visible_all',
		method: 'POST',
		data: {project_id, dataset_id, file_id, visible, token}
	});
}

export function updateInspect({id, inspect, token}) {
	return request({
		url: '/api/project/update_inspect',
		method: 'POST',
		data: {id, inspect, token}
	});
}

export function updateAnnotation(payload) {
	return request({
		url: '/api/annotation/update',
		method: 'POST',
		data: payload
	});
}

export function getAnnotations({ project_id, token }) {
	return request({
		url: '/api/getAnnotations',
		method: 'POST',
		data: { project_id, token }
	});
}

export function getAnnotationByID({ id, token }) {
	return request({
		url: '/api/annotation/get',
		method: 'POST',
		data: { id, token }
	});
}

export function getAnnotationByDataset({ project_id, dataset_id, token }) {
	return request({
		url: '/api/getAnnotations',
		method: 'POST',
		data: { project_id, dataset_id, token }
	});
}

export function getAnnotationByFile({ project_id, dataset_id, file_id, token }) {
	return request({
		url: '/api/getAnnotations',
		method: 'POST',
		data: { project_id, dataset_id, file_id, token }
	});
}

export function deleteAnnotationByID({ id, token }) {
	return request({
		url: '/api/annotation/delete',
		method: 'POST',
		data: { id, token }
	});
}

export function deleteAllAnnotation({ project_id, dataset_id, file_id, token }) {
	return request({
		url: '/api/annotation/delete_all',
		method: 'POST',
		data: { project_id, dataset_id, file_id, token }
	});
}

export function openNewWindow(url) {
	window.open(url, '_blank').focus();
}

export default request;
